import { HttpClient, HttpResponse } from '@angular/common/http'
import { inject, Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import {
   GVOCommentEntity,
   GVOContactResponsibleRequest,
   GVOPartnerEntity,
   GVOResponsibleEntity,
   GVOResult,
} from '../../../features/feature-linking-tool/grant-vs-org/models/gvo-result.interface'
import { GVOSearchFields } from '../../../features/feature-linking-tool/grant-vs-org/models/gvo-search-fields.interface'
import { MPIResult } from '../../../features/feature-linking-tool/manage-proposed-ideas/models/mpi-result.interface'
import { PSPRequest, PSPResult } from '../../../features/feature-linking-tool/proj-seeking-partners/models/psp-request.intraface'
import { BasicResult } from '../../models/interfaces/basic-result.interface'
import { BasicSearchFields } from '../../models/interfaces/basic-search-fields.interface'
import { ConfigService } from '../../services/config.service'
import { LogService } from '../../services/log.service'
import { GVODetailResult } from '../../../features/feature-linking-tool/grant-vs-org/models/gvo-detail-result.interface'

@Injectable({ providedIn: 'root' })
export class FLinkingToolService {
   private readonly _cn = this.constructor.name
   private readonly _httpClient = inject(HttpClient)
   private readonly _logService = inject(LogService)
   private readonly _configService = inject(ConfigService)

   /* Grants vs Organisations */
   searchGVOData(searchFields: GVOSearchFields): Observable<BasicResult<GVOResult>> {
      this._logService.info(this._cn, this.searchGVOData.name, this._logService.START)
      this._logService.debug(this._cn, this.searchGVOData.name, 'search fields:', searchFields)
      return this._httpClient.post<BasicResult<GVOResult>>(`${this._configService.old_BE_ApiUrl}` + '/matchmaking/search', searchFields)
   }

   searchGrantFilteredByName(grantName: string): Observable<string[]> {
      this._logService.info(this._cn, this.searchGrantFilteredByName.name, this._logService.START)
      this._logService.debug(this._cn, this.searchGrantFilteredByName.name, 'search grantName:', grantName)
      return this._httpClient.post<string[]>(`${this._configService.old_BE_ApiUrl}` + `/matchmaking/autocompleteGrant/${grantName}`, {})
   }

   downloadGVOData(searchFields: GVOSearchFields): Observable<HttpResponse<Blob>> {
      this._logService.info(this._cn, this.downloadGVOData.name, this._logService.START)
      this._logService.debug(this._cn, this.downloadGVOData.name, 'search fields:', searchFields)
      return this._httpClient.post<Blob>(`${this._configService.old_BE_ApiUrl}` + `/matchmaking/search/export`, searchFields, {
         headers: { Accept: '*/*' },
         responseType: 'blob' as 'json',
         observe: 'response',
      })
   }

   addGVOComment(request: Partial<GVOCommentEntity>): Observable<GVOCommentEntity> {
      this._logService.info(this._cn, this.addGVOComment.name, this._logService.START)
      this._logService.debug(this._cn, this.addGVOComment.name, 'add comment request:', request)
      return this._httpClient.post<GVOCommentEntity>(`${this._configService.old_BE_ApiUrl}` + '/matchmaking/addComment', request)
   }

   deleteGVOComment(request: Partial<GVOCommentEntity>) {
      this._logService.info(this._cn, this.deleteGVOComment.name, this._logService.START)
      this._logService.debug(this._cn, this.deleteGVOComment.name, 'deleting comment request:', request)
      return this._httpClient.delete(`${this._configService.old_BE_ApiUrl}` + `/matchmaking/deleteComment`, { body: request })
   }

   addGVOLeadingEntity(request: Partial<GVOPartnerEntity>): Observable<GVOPartnerEntity> {
      this._logService.info(this._cn, this.addGVOLeadingEntity.name, this._logService.START)
      this._logService.debug(this._cn, this.addGVOLeadingEntity.name, 'add leading entity request:', request)
      return this._httpClient.post<GVOPartnerEntity>(`${this._configService.old_BE_ApiUrl}` + `/matchmaking/addLeadingPartner`, request)
   }

   deleteGVOLeadingEntity(request: Partial<GVOPartnerEntity>) {
      this._logService.info(this._cn, this.deleteGVOLeadingEntity.name, this._logService.START)
      this._logService.debug(this._cn, this.deleteGVOLeadingEntity.name, 'deleting leading entity request:', request)
      return this._httpClient.delete(`${this._configService.old_BE_ApiUrl}` + `/matchmaking/deleteLeadingPartner`, { body: request })
   }

   addGVOPartner(request: Partial<GVOPartnerEntity>): Observable<GVOPartnerEntity> {
      this._logService.info(this._cn, this.addGVOPartner.name, this._logService.START)
      this._logService.debug(this._cn, this.addGVOPartner.name, 'add partner request:', request)
      return this._httpClient.post<GVOPartnerEntity>(`${this._configService.old_BE_ApiUrl}` + `/matchmaking/addPartner`, request)
   }

   deleteGVOPartner(request: Partial<GVOPartnerEntity>) {
      this._logService.info(this._cn, this.deleteGVOPartner.name, this._logService.START)
      this._logService.debug(this._cn, this.deleteGVOPartner.name, 'deleting partner request:', request)
      return this._httpClient.delete(`${this._configService.old_BE_ApiUrl}` + `/matchmaking/deletePartner`, { body: request })
   }

   addGVOResponsible(request: Partial<GVOResponsibleEntity>): Observable<GVOResponsibleEntity> {
      this._logService.info(this._cn, this.addGVOResponsible.name, this._logService.START)
      this._logService.debug(this._cn, this.addGVOResponsible.name, 'add responsible request:', request)
      return this._httpClient.post<GVOResponsibleEntity>(`${this._configService.old_BE_ApiUrl}` + `/matchmaking/addResponsible`, request)
   }

   addGVOPNOResponsible(matchmakingId: string): Observable<GVOResponsibleEntity> {
      this._logService.info(this._cn, this.addGVOPNOResponsible.name, this._logService.START)
      this._logService.debug(this._cn, this.addGVOPNOResponsible.name, 'add PNO responsible request:', matchmakingId)
      return this._httpClient.post<GVOResponsibleEntity>(`${this._configService.old_BE_ApiUrl}` + `/matchmaking/addPnoResponsible/${matchmakingId}`, {})
   }

   deleteGVOResponsible(request: Partial<GVOResponsibleEntity>) {
      this._logService.info(this._cn, this.deleteGVOResponsible.name, this._logService.START)
      this._logService.debug(this._cn, this.deleteGVOResponsible.name, 'deleting responsible request:', request)
      return this._httpClient.delete(`${this._configService.old_BE_ApiUrl}` + `/matchmaking/deleteResponsible`, { body: request })
   }

   contactGVOResponsible(grantId: number, request: GVOContactResponsibleRequest) {
      this._logService.info(this._cn, this.contactGVOResponsible.name, this._logService.START)
      this._logService.debug(this._cn, this.contactGVOResponsible.name, 'contact PNO responsible request:', request)
      return this._httpClient.post(`${this._configService.old_BE_ApiUrl}` + `/matchmaking/${grantId}/notify`, request)
   }

   getGrantDataByID(id: number): Observable<GVODetailResult> {
      this._logService.info(this._cn, this.getGrantDataByID.name, this._logService.START)
      this._logService.debug(this._cn, this.getGrantDataByID.name, 'request id:', id)
      return this._httpClient.get<GVODetailResult>(`${this._configService.old_BE_ApiUrl}` + `/grants/${id}`)
   }

   /* Manage proposed ideas */
   searchMPIData(request: BasicSearchFields): Observable<BasicResult<MPIResult>> {
      this._logService.info(this._cn, this.searchMPIData.name, this._logService.START)
      this._logService.debug(this._cn, this.searchMPIData.name, 'search fields:', request)
      return this._httpClient.post<BasicResult<MPIResult>>(`${this._configService.old_BE_ApiUrl}` + `/proposedProjects/search`, request)
   }

   getMPIDataByID(id: number): Observable<MPIResult> {
      this._logService.info(this._cn, this.getMPIDataByID.name, this._logService.START)
      this._logService.debug(this._cn, this.getMPIDataByID.name, 'request id:', id)
      return this._httpClient.get<MPIResult>(`${this._configService.old_BE_ApiUrl}` + `/proposedProjects/${id}`)
   }

   markMPIDataAsProcessed(id: number) {
      this._logService.info(this._cn, this.markMPIDataAsProcessed.name, this._logService.START)
      this._logService.debug(this._cn, this.markMPIDataAsProcessed.name, 'mark as processed mpi item id:', id)
      return this._httpClient.put(`${this._configService.old_BE_ApiUrl}` + `/proposedProjects/${id}/updateProcessed?processed=true`, {})
   }

   deleteMPIData(id: number) {
      this._logService.info(this._cn, this.deleteMPIData.name, this._logService.START)
      this._logService.debug(this._cn, this.deleteMPIData.name, 'deleting item with id:', id)
      return this._httpClient.delete(`${this._configService.old_BE_ApiUrl}` + `/proposedProjects/${id}`)
   }

   /* Project seeking partners */
   getPSPData(request: PSPRequest): Observable<PSPResult> {
      this._logService.info(this._cn, this.getPSPData.name, this._logService.START)
      this._logService.debug(this._cn, this.getPSPData.name, 'search fields:', request)
      let req = { ...request, filtering: { ...request.filtering, statuses: [1, 2], partnerSearchUnexpired: true } }
      return this._httpClient.post<PSPResult>(`${this._configService.new_BE_ApiUrl}` + `/solr/projects:search`, req)
   }
}
